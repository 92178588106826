
.autocomplete-dropdown {
    position: absolute;
    border: 1px solid #ccc;
    background: #fff;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    min-width: 500px;
}

.autocomplete-suggestion {
    padding: 10px;
    cursor: pointer;
}

.autocomplete-suggestion:hover {
    background: #f0f0f0;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    /* Darker background for more emphasis */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    max-width: 80%;
    width: 80%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.modal-close-button {
    text-align: right;
    align-items: end;
    justify-content: right;
    background: none;
    border: none;
    font-size: 3.5rem;
    cursor: pointer;
    color: #d31919;
    transition: color 0.3s ease;
}

.modal-close-button:hover {
    color: #333;
}

.scrollable-table-container {
    overflow-x: auto;
}

.scrollable-cell {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding: 8px;
}

.scrollable-cell:hover {
    overflow: auto;
    white-space: normal;
    z-index: 1;
    background: white;
}

.srfq-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.srfq-table th,
.srfq-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.srfq-table th {
    background-color: #f2f2f2;
    color: #333;
}

.srfq-table td {
    background-color: #fff;
}

.addsaledrequstsection {

    text-align: right;
    align-items: end;
    justify-content: end;
    margin: 20px;


}
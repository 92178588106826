.circle {
    position: relative;
    display: inline-block;
    margin: 20px;
    width: 100px;
    height: 100px;
  }
  
  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  .circular-chart.orange .circle {
    stroke:#fc3c0c;
  }
  
  .percentage {
    font-size: 0.5em;
    text-anchor: middle;
    fill: #999;
  }
  
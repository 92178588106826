.fixed-header-table {
  max-height: 500px;
  /* Adjust height as needed */
  overflow-y: auto;
  position: relative;
}

.fixed-header-table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  /* Ensure the header has a background */
}


.info-section {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.info-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-content {
  display: flex;
  flex-direction: column;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-icon1 {
  width: 30px;
  height: 30px;
  background-color: #2ee534;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.info-icon2 {
  width: 30px;
  height: 30px;
  background-color: #ff003c;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.info-icon3 {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.info-text {
  font-size: 1rem;
  color: #333;
}
/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content container */
  .modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70%; /* Adjust to fit large images */
    max-height: 80%; /* Prevent overflow */
    overflow: hidden; /* Hide overflow */
  }
  
  
  /* Modal image */
  .modal-image {
    max-width: 50%;
    max-height: 70%;
    transition: transform 0.3s ease;
  }
  
  /* Button group for zoom and close buttons */
  .button-group2 {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
  }
  
  /* Circular button styling */
  .circle-button {
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .circle-button:hover {
    background-color: #f0f0f0;
  }
  
  /* Ensuring the buttons stay on top of content */
  .circle-button {
    z-index: 1001;
  }
  
 
  .card-container {
   display: flex;
 }
 .card-body {
   flex: 1; /* Optional: if you want both cards to take equal space */
 }
 
 .promotion-details-card {
   background-color: white;
   position: fixed;
   top: 13%;
   width: 100%;
   height: 200px;
   z-index: 1;
 }
 
 .promotion-details-card-body{
   left: 0;
   color: green;
   background-color: blue;
 }
 

 .scrollable-container {
  height: 1000px; /* Adjust this height as needed */
  overflow-y: auto;
}

.card-body {
  flex: 1;
}
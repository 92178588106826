.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: auto; /* Ensure scrolling */
}

.custom-modal-content {
    background: #fff;
    border-radius: 12px;
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    animation: fadeIn 0.3s ease-out;
    max-height: 90vh;
    overflow-y: auto; /* Ensure vertical scrolling */
}

.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.custom-modal-header h2 {
    margin: 0;
    font-size: 1.6rem;
    color: #333;
}

.custom-close {
    font-size: 30px;
    cursor: pointer;
    color: #d30b0b;
    transition: color 0.3s;
}

.custom-close:hover {
    color: #000;
}

.custom-modal-body p {
    margin: 10px 0;
    font-size: 1rem;
    color: #555;
}

.custom-modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #eee;
    padding-top: 10px;
}

.custom-modal-footer button {
    margin-left: 10px;
}

.btn-secondary {
    background: #ccc;
    border: none;
    color: #333;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-primary {
    background: #de0f0f;
    border: none;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-secondary:hover {
    background: #bbb;
}

.btn-primary:hover {
    background: #a20202;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    table-layout: auto;
}

.custom-table th,
.custom-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.custom-table th {
    background: #f1f1f1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.custom-table tbody tr:nth-child(even) {
    background: #f9f9f9;
}

.custom-table tbody tr:hover {
    background: #e9ecef;
}

.scrollable-text {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.scrollable-text:hover {
    overflow: visible;
    white-space: normal;
    background: #fff;
    z-index: 2;
}

.custom-table img {
    max-width: 100px;
    height: auto;
    display: block;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

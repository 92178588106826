.form-control-modern {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-modern:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.modal-content {
    border-radius: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

.list-group-item {
    cursor: pointer;
        background-color: #f8f9fa;
   
}

.list-group-item:hover {
    background-color: #f8f9fa;
}
/* Calendar specific styles */
.calendar-container {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 24px;
    font-family: 'Inter', sans-serif;
}

.calendar-wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 16px;
}

.calendar-navigation {
    display: flex;
    align-items: center;
    gap: 16px;
}

.current-month {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
}

.view-options {
    display: flex;
    align-items: center;
    gap: 8px;
}

.today-button {
    padding: 8px 16px;
    font-size: 14px;
    color: #ffffff;
    background-color: #de0f0f;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.today-button:hover {
    background-color: #a20202;
}

.view-switcher {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 6px;
    overflow: hidden;
}

.view-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    color: #666666;
    transition: all 0.3s ease;
}

.view-button:hover {
    background-color: #f8f9fa;
}

.view-button.active {
    background-color: #de0f0f;
    color: #ffffff;
    font-weight: 600;
}

.calendar-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    text-align: center;
}

.calendar-table th {
    padding: 12px;
    border: 1px solid #eeeeee;
    background-color: #f3f3f3;
    color: #555555;
    font-weight: 600;
    text-transform: uppercase;
}

.calendar-cell {
    padding: 12px;
    border: 1px solid #eeeeee;
    height: 120px;
    vertical-align: top;
    position: relative;
    transition: background-color 0.3s ease;
}

.calendar-cell:hover {
    background-color: #f9f9f9;
}

.prev-month,
.next-month {
    color: #cccccc;
}

.current-day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: #de0f0f;
    color: #ffffff;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
}

.event-container {
    max-height: 70px;
    /* Adjust as needed */
    overflow: hidden;
    position: relative;
}

.event-list {
    max-height: 70px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.event-list::-webkit-scrollbar {
    width: 6px;
}

.event-list::-webkit-scrollbar-thumb {
    background-color: #e32b2b;
    border-radius: 3px;
}

.event-list::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.event {
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
    margin: 2px 0;
    font-size: 0.75rem;
    transition: opacity 0.3s ease;
}

.event:hover {
    opacity: 0.8;
    cursor: pointer;
}

/* Specific status colors */
.event-scheduled {
    background-color: #3b82f6;
    /* Blue */
}

.event-completed {
    background-color: #10b981;
    /* Green */
}

.event-cancelled {
    background-color: #ef4444;
    /* Red */
}

.event-default {
    background-color: #6b7280;
    /* Gray */
}

.event-overflow {
    text-align: center;
    font-size: 0.7em;
    color: #de0f0f;
    padding: 2px;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #888;
}

.event-details {
    margin-top: 20px;
}

.detail-row {
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.detail-label {
    font-weight: bold;
    width: 40%;
    color: #555;
}

.detail-value {
    width: 60%;
    color: #333;
}

.modal-scroll-container {
    overflow-y: auto;
    padding-right: 10px;
    max-height: calc(100% - 100px);
    /* Adjust for footer */
}

.modal-footer {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
}

.confirm-registration-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-registration-btn:hover {
    background-color: #45a049;
}

.confirm-registration-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Customize scrollbar for webkit browsers */
.modal-scroll-container::-webkit-scrollbar {
    width: 8px;
}

.modal-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modal-scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.modal-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.star-rating {
    display: inline-flex;
    align-items: center;
}

.star {
    color: #ddd;
    cursor: pointer;
    font-size: 24px;
    transition: color 0.2s;
}

.star.active {
    color: #ffc107;
}

.rating-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.rating-row span {
    margin-right: 10px;
}

.comment-section {
    margin-top: 20px;
}

.comment-section textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
}

.feedback-section {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

/* EventsPage.css */
:root {
    --primary-color: #3B82F6;
    --secondary-color: #10B981;
    --text-color: #1F2937;
    --background-color: #F3F4F6;
    --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.events-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--background-color);
}

.events-view-switcher {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 20px;
}

.view-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #E5E7EB;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
}

.view-button.active {
    background-color: var(--primary-color);
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 25px;
}

.event-card {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: var(--card-shadow);
    transition: transform 0.3s ease;
}

.event-card:hover {
    transform: translateY(-10px);
}

.event-card-image-container {
    position: relative;
    height: 200px;
    overflow: hidden;
}

.event-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-status {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
}

.status-scheduled {
    background-color: #3B82F6;
    color: white;
}

.status-completed {
    background-color: #10B981;
    color: white;
}

.status-cancelled {
    background-color: #EF4444;
    color: white;
}

.status-default {
    background-color: #6B7280;
    color: white;
}

.event-card-content {
    padding: 15px;
}

.event-card-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--text-color);
}

.event-card-details {
    margin-bottom: 15px;
}

.event-detail {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: #6B7280;
}

.event-detail i {
    margin-right: 10px;
    color: var(--primary-color);
}

.event-card-button {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.event-card-button:hover {
    background-color: #2563EB;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.spinner {
    border: 4px solid #F3F4F6;
    border-top: 4px solid var(--primary-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
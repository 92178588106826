.description-cell {
    position: relative;
    overflow: hidden;
    max-height: 20px;
    cursor: pointer;
  }
  
  .description-cell:hover {
    max-height: none;
    white-space: pre-wrap; /* Ensures new lines are respected */
  }
  
  .description-cell span {
    display: block;
  }
  
/* ProductCard.css */
.product-card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.product-card:hover {
    transform: scale(1.02);
}

.eol-label {
    background-color: #ff5252;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.new-arrivals-img-contnent img {
    border-radius: 10px;
}

.price {
    font-size: 1.2em;
    color: #333;
    margin-top: 5px;
}

.quantity-control {
    display: flex;
    align-items: center;
    gap: 10px;
}

.quantity-button {
    background-color: #f12727;
    /* Green */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.quantity-button:hover {
    background-color: #c41313;
}

.quantity-input1 {
    width: 100px;
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    outline: none;
    transition: border-color 0.3s ease;
}

.quantity-input:focus {
    border-color: #ba1111;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.custom-button {
    padding: 5px 10px;
    font-size: 0.9em;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.custom-button.btn-primary {
    background-color: #ff0008;
    color: white;
    border: none;
}

.custom-button.btn-success {
    background-color: #5b4d4d;
    color: white;
    border: none;
}


.custom-button.btn-success:hover {
    background-color: #322929;
    color: white;
    border: none;
}




.custom-button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
}

.text-danger {
    font-size: 0.9em;
}

.eol-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
}

.fixed-cart-button-container {
    margin-bottom: 20px;
    align-items: end;
    justify-content: end;
    text-align: right;
}

.go-to-cart {
    display: flex;
    align-items: center;
    justify-content: center;
}







.cart-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    margin-left: 5px;
}

.cart-items {
    padding: 15px;
}

.cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 10px;
}

.cart-item-image {
    max-width: 100px;
    max-height: 100px;
    margin-right: 15px;
}

.cart-item-details {
    flex: 1;
}

.cart-item-details h5 {
    margin: 0;
    font-size: 1.25rem;
}

.cart-item-details p {
    margin: 0.5rem 0;
}

.cart-total {
    margin-top: 20px;
    text-align: right;
}

.cart-total h5 {
    font-size: 1.25rem;
    font-weight: bold;
}





.filter-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
    min-width: 150px;
}

.filter-buttons .btn {
    width: 45%;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
}

.filter-buttons .btn-active {
    background-color: #4228ef;
    color: white;
}

.scrollable-list {
    max-height: 300px;
    overflow-y: auto;
}

.scrollable-list ul {
    list-style: none;
    padding: 0;
}

.scrollable-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.search-bar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

.search-bar {
    width: 50%;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 50px;
    outline: none;
    transition: border-color 0.3s ease;
}

.search-bar:focus {
    border-color: #007bff;
}

.search-button {
    position: absolute;
    right: 25%;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.search-button i {
    font-size: 18px;
    color: #007bff;
}

.search-button:focus i,
.search-button:hover i {
    color: #d51818;
}


.filter-modal .modal-body {
    max-height: 70vh;
    overflow-y: auto;
}

.filter-content .filter-section {
    margin-bottom: 20px;
}

.filter-content h5 {
    margin-bottom: 10px;
}

.checkbox-list {
    display: flex;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
}

.checkbox-list .form-check {
    width: 50%;
    margin-bottom: 5px;
}





@media (max-width: 768px) {
    .checkbox-list .form-check {
        width: 100%;
    }
}



.product-detail-content {
    padding: 10px;
}

.product-detail-content h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.product-detail-content .price {
    font-size: 1.25rem;
    color: #333;
}

/* .product-detail-content .item {
    font-weight: bold;
    color: #333;
} */

.product-detail-content .quantity {
    margin-top: 10px;
}

.product-detail-content .btn-primary {
    margin-top: 20px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}



.scrollable-dropdown-menu {
    margin-bottom: 50px;
    max-height: 900px;
 
    overflow-y: auto;

}


/* #dropdown-basic{

    margin-bottom: 250px;

} */



.modal-custom .modal-content {
    /* max-height: 700px; */
    overflow-y: auto;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.modal-custom .modal-header,
.modal-custom .modal-footer {
    border: none;
    margin-bottom: 20px;
}

.modal-custom .modal-title {
    font-size: 24px;
    font-weight: bold;
}

.cart-items-scrollable {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.cart-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.cart-item:hover {
    background-color: #f8f9fa;
}

.cart-item-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 15px;
}

.cart-item-details {
    flex-grow: 1;
}

.cart-item-details h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.cart-item-details p {
    margin: 2px 0;
    font-size: 14px;
}

.cart-item.free-item {
    background-color: #e6f7ff;
    border-left: 4px solid #ff181c;
}

.free-item-label {
    color: #ff1833;
    font-weight: bold;
    margin-left: 10px;
}

/* Delete Button */
.delete-button {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
}

.delete-button:hover {
    color: #a71d2a;
}

/* Quantity Control */
.quantity-control {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.quantity-button {
    background: none;
    border: none;
    color: #007bff;
    font-size: 1.2em;
    cursor: pointer;
    padding: 0 5px;
}

.quantity-button:hover {
    color: #0056b3;
}

.quantity-input1 {
    width: 50px;
    text-align: center;
    margin: 0 5px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 2px 5px;
}






/* Discount Details */
.discount-details p {
    font-size: 14px;
    margin: 2px 0;
}

/* Fixed Buttons */
.fixed-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 300px;
    margin: 10px 0;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
}

.total-price {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}






/* Filters  */

.category-filter {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 200px;
    margin-top: 20px;
}

.filter-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
}

.custom-dropdown {
    position: relative;
    margin-bottom: 10px;
    margin-left: 20px;
}

.dropdown-toggle {
    width: 100%;
    padding: 12px 15px;
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    color: #333;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dropdown-toggle:hover {
    background-color: #e9ecef;
}

/* .dropdown-arrow {
  float: right;
  transition: transform 0.3s ease;
} */

/* .custom-dropdown:hover .dropdown-arrow {
  transform: rotate(180deg);
} */

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: none;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.custom-dropdown:hover .dropdown-menu {
    display: block;
    overflow-y: auto;
}

.dropdown-menu li {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
    background-color: #f1f3f5;
}
